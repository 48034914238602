<template>
  <CreateUpdateTemplate
    :customClass="'contract-create'"
    v-if="getPermission('contract:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        Create new Contract For
        <p
          class="m-0 form-title-create-link pl-2"
          :class="{
            'text-ellipsis width-350px': !lodash.isEmpty(customerObject),
          }"
          link
        >
          <template v-if="lodash.isEmpty(customerObject)">Client Name</template>
          <template v-else>{{ customerObject.display_name }}</template>
          <v-icon
            link
            large
            color="cyan"
            class="mx-2"
            v-if="lodash.isEmpty(customerObject)"
            >mdi-plus-circle-outline</v-icon
          >
        </p>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Contract
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="contractForm"
        v-model="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Contract Subject
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Subject"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="contractCreate.subject"
                      dense
                      filled
                      label="Subject"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rules="[
                        validateRules.required(
                          contractCreate.subject,
                          'Contract Subject'
                        ),
                        validateRules.minLength(
                          contractCreate.subject,
                          'Contract Subject',
                          1
                        ),
                        validateRules.maxLength(
                          contractCreate.subject,
                          'Contract Subject',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">SOled Job No.</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="SOled Job No."
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contractCreate.soled_job_no"
                      label="SOled Job No."
                      :rules="[
                        validateRules.minLength(
                          contractCreate.soled_job_no,
                          'SOled Job No.',
                          1
                        ),
                        validateRules.maxLength(
                          contractCreate.soled_job_no,
                          'SOled Job No.',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr v-if="false">
                  <td class="font-size-16 pb-2" width="200">Project</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Name"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="projectList"
                      label="Project"
                      solo
                      flat
                      :disabled="pageLoading || !!+$route.query.project"
                      :loading="pageLoading"
                      hide-details
                      item-text="name"
                      item-value="id"
                      v-model="contractCreate.project"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Project Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >#{{ item.barcode }} -
                            {{ item.name }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Start Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Start Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      :pageLoading.sync="pageLoading"
                      :placeholder="'Start Date'"
                      v-model="contractCreate.start_date"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">End Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="End Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      :pageLoading.sync="pageLoading"
                      :placeholder="'End Date'"
                      v-model="contractCreate.end_date"
                      :defaultDate.sync="defaultEndDate"
                    ></DatePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">Contract number</td>
                  <td class="font-weight-700 font-size-16 pb-4" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        v-model="contractCreate.barcode"
                        label="Contract number"
                        solo
                        flat
                        hide-details
                        class="remove-border cursor-default"
                      ></v-text-field>
                    </template>
                  </td>
                  <td class="font-size-16 pb-4">
                    <v-btn
                      text
                      small
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="barcodeDialog = true"
                      color="cyan"
                    >
                      Change
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Reference #</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Reference #"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contractCreate.reference"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          contractCreate.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          contractCreate.reference,
                          'Reference',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Contract Type</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Contract Type"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="contractTypeList"
                      label="Contract Type"
                      solo
                      flat
                      hide-details
                      item-text="text"
                      item-value="value"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageContractTypeDialog = true"
                      v-model="contractCreate.contract_type"
                      :rules="[
                        validateRules.required(
                          contractCreate.contract_type,
                          'Contract Type'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Contract Type Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Contract Value</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Contract Value"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="contractCreate.contract_value"
                      dense
                      filled
                      label="Contract Value"
                      solo
                      flat
                      hide-details
                      type="number"
                      min="0"
                      prepend-inner-icon="mdi-currency-usd"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      v-on:keypress="
                        limitDecimal($event, contractCreate.contract_value)
                      "
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
              v-if="
                contractCreate.customer && getPermission('line-item:create')
              "
            >
              <v-container fluid class="px-0">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="12" class="pt-0 pb-0">
                        <ContractLineItem
                          isContract
                          :update-data="dbLineItems"
                          :customer.sync="contractCreate.customer"
                          :pageLoading.sync="pageLoading"
                        ></ContractLineItem>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <ContractLineItemCalculation
                          isContract
                          :pageLoading.sync="pageLoading"
                        ></ContractLineItemCalculation>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col md="12" class="py-0">
              <editor v-model="contractCreate.description" />
            </v-col>
            <v-col md="12">
              <FileTemplate
                allowUpload
                v-on:file:updated="updateAttachment"
              ></FileTemplate>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
      <ManageContractType
        :commonDialog.sync="manageContractTypeDialog"
        :contractType.sync="contractTypeList"
        v-on:close-dialog="manageContractTypeDialog = false"
        v-on:get-contract-type="getOptions(['contract_type'])"
      ></ManageContractType>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="contract"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:update:barcode-setting="
          getOptions([
            'barcode_setting',
            'barcode',
            'contract_type',
            'project_list',
          ])
        "
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, POST, QUERY } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import ManageContractType from "@/view/pages/partials/Manage-Contract-Type.vue";
import moment from "moment-timezone";
import ContractLineItem from "@/view/pages/partials/Line-Item.vue";
import ContractLineItemCalculation from "@/view/pages/partials/Line-Item-Calculation.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import ObjectPath from "object-path";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "contract-create",
  title: "Create Contract",
  data() {
    return {
      contract: null,
      pageLoading: false,
      barcodeDialog: false,
      manageContractTypeDialog: false,
      barcodeSetting: new Object(),
      customerObject: new Object(),
      contractTypeList: new Array(),
      projectList: new Array(),
      lineItem: new Array(),
      lineItemCalculation: new Object(),
      duplicateId: 0,
      doDuplicate: false,
      contractCreate: new Object({
        reference: null,
        soled_job_no: null,
        customer: null,
        project: null,
        subject: null,
        contract_value: null,
        contract_type: null,
        description: null,
        start_date: null,
        end_date: null,
        status: null,
        attachments: [],
      }),
      dbLineItems: [],
    };
  },
  components: {
    DatePicker,
    FileTemplate,
    BarcodeSetting,
    ContractLineItem,
    ContractLineItemCalculation,
    ManageContractType,
    CreateUpdateTemplate,
    editor: TinyMCE,
  },
  methods: {
    updateAttachment(param) {
      this.contractCreate.attachments = param;
    },
    getOptions(attributes) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "contracts/options",
          data: { attributes, customer: _this.contractCreate.customer },
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data.customer) === false) {
            _this.customerObject = data.customer;
          }
          if (_this.lodash.isEmpty(data.barcode_setting) === false) {
            _this.barcodeSetting = data.barcode_setting;
          }
          if (_this.lodash.isEmpty(data.barcode) === false) {
            _this.contractCreate.barcode = data.barcode;
          }
          if (_this.lodash.isEmpty(data.contract_type) === false) {
            _this.contractTypeList = data.contract_type;
          }
          if (_this.lodash.isEmpty(data.project_list) === false) {
            _this.projectList = data.project_list;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.manageContractTypeDialog = false;
        });
    },
    createLineItems() {
      return new Promise((resolve, reject) => {
        try {
          const _this = this;
          let lineItemFormData = new Array();
          for (let i = 0; i < _this.lineItem.length; i++) {
            lineItemFormData.push({
              id: _this.lineItem[i].id,
              product: _this.lineItem[i].product.id,
              product_type: _this.lineItem[i].product.product_type,
              description: _this.lineItem[i].description,
              name: ObjectPath.get(_this.lineItem[i], "name"),
              quantity: _this.lineItem[i].quantity,
              discount: _this.lineItem[i].discount,
              rate: _this.lineItem[i].rate,
              warranty: _this.lineItem[i].warranty,
              warranty_object: _this.lineItem[i].warranty_object,
              warranty_option: _this.lineItem[i].warranty_option,
              minimum_rental: _this.lineItem[i].minimum_rental,
              maximum_rental: _this.lineItem[i].maximum_rental,
            });
          }

          const formData = {
            contract: _this.contract,
            line_items: lineItemFormData,
          };

          _this.formLoading = true;
          _this.$store.dispatch(CLEAR_ERROR, {});
          _this.$store
            .dispatch(POST, {
              url: "line-item",
              data: formData,
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.formLoading = false;
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    updateOrCreate() {
      const _this = this;

      const validateStatus = _this.$refs.contractForm.validate();

      const formErrors = _this.validateForm(_this.$refs.contractForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      let validateLineItem = _this.lodash.compact(
        _this.lodash.map(_this.lineItem, function (row) {
          return row.product;
        })
      );

      if (_this.lodash.isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/equipment then try again.")
        );
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        soled_job_no: _this.contractCreate.soled_job_no || null,
        reference: _this.contractCreate.reference || null,
        customer: _this.contractCreate.customer || null,
        project: _this.contractCreate.project || null,
        subject: _this.contractCreate.subject || null,
        contract_value: _this.contractCreate.contract_value || null,
        contract_type: _this.contractCreate.contract_type || null,
        description: _this.contractCreate.description || null,
        start_date: _this.contractCreate.start_date || null,
        end_date: _this.contractCreate.end_date || null,
        status: _this.contractCreate.status || null,
        attachments: _this.contractCreate.attachments || [],
      });

      _this.$store
        .dispatch(POST, {
          url: "contracts",
          data: formData,
        })
        .then(({ data }) => {
          _this.contract = _this.lodash.toSafeInteger(data.id);
          if (_this.getPermission("line-item:create")) {
            _this
              .createLineItems()
              .then(() => {
                _this.$router.push(
                  _this.getDefaultRoute("contract.detail", {
                    params: { id: _this.contract },
                  })
                );
              })
              .catch((error) => {
                _this.logError(error);
              });
          } else {
            _this.$router.push(
              _this.getDefaultRoute("contract.detail", {
                params: { id: _this.contract },
              })
            );
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getDuplicate() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, { url: `contracts/${_this.duplicateId}/duplicate` })
        .then(({ data }) => {
          _this.contractCreate.reference = data.contract.reference;
          _this.contractCreate.soled_job_no = data.contract.soled_job_no;
          _this.contractCreate.customer = data.contract.customer;
          _this.contractCreate.project = data.contract.project;
          _this.contractCreate.subject = data.contract.subject;
          _this.contractCreate.contract_value = data.contract.contract_value;
          _this.contractCreate.contract_type = data.contract.contract_type;
          _this.contractCreate.description = data.contract.description;
          _this.contractCreate.start_date = data.contract.start_date;
          _this.contractCreate.end_date = data.contract.end_date;
          _this.dbLineItems = data.line_items.map((row) => {
            row.id = null;
            return row;
          });
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        });
    },
  },
  created() {
    const _this = this;
    _this.contractCreate.customer = _this.lodash.toSafeInteger(
      _this.$route.query.customer
    );
    if (_this.contractCreate.customer <= 0) {
      _this.goBack();
    }
    _this.contractCreate.project = _this.lodash.toSafeInteger(
      _this.$route.query.project
    );

    _this.duplicateId = ObjectPath.get(_this.$route, "query.duplicate", 0);
    _this.doDuplicate = ObjectPath.has(_this.$route, "query.duplicate");

    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = argument;
    });

    LineItemCalculationEventBus.$on(
      "update:line-item-calculation",
      (argument) => {
        _this.lineItemCalculation = argument;
      }
    );
  },
  mounted() {
    const _this = this;
    _this.getOptions([
      "customer",
      "barcode_setting",
      "barcode",
      "contract_type",
      "project_list",
    ]);

    if (_this.doDuplicate) {
      _this.getDuplicate();
    }

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Contract", route: "contract" },
      { title: "Create" },
    ]);
  },
  computed: {
    defaultEndDate() {
      return moment().add(30, "days").format("YYYY-MM-DD");
    },
  },
};
</script>
